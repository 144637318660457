import { t } from 'i18next';

import Heading from '../../../components/Heading';
import styles from './styles.module.css';
import { VideoPlayer } from '../../../components/VideoPlayer';
import { useState } from 'react';

interface RewardProps {
    congratulation?: {
        title?: string;
        msg?: string;
        video?: string;
    };
    onNext?: () => void;
};

const Reward: React.FC<RewardProps> = ({ congratulation, onNext }) => {
    const [play, setPlay] = useState(true);

    const handleNext = () => {
        setPlay(false);
        onNext && onNext();
    }

    return (
        <div className={styles.container}>
            <div className={styles.videoContainer}>
                {congratulation?.video && play &&
                        <VideoPlayer url={congratulation.video} autoPlay={true} sx={{ borderRadius: 'var(--radius-l)', overflow: 'hidden' }} />
                }
            </div>

            {onNext && <div className={styles.actionsContainer}>
                <div className={styles.actions} onClick={() => handleNext()}>
                    <div className={styles.cta}>
                        <Heading center className={styles.label}>{t('Continuer')}</Heading>
                    </div>
                </div>
            </div>}
        </div >
    );
};

export default Reward;