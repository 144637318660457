import { useEffect, useRef, ReactNode, MouseEvent } from 'react';

import style from './style.module.css';
import { useHistory } from 'react-router';

interface CustomImage {
    Image?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    className?: string;
}

interface WindowProps {
    children?: ReactNode;
    isOpened?: boolean;
    customImage: CustomImage
}

const Window = ({ children, customImage, isOpened = false }: WindowProps) => {
    const ref = useRef<HTMLDivElement>(null);
    // const mouseDownTarget = useRef<EventTarget | null>(null);

    useEffect(() => {
        document.body.style.overflow = isOpened ? 'hidden' : '';
        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpened]);

    return (
        <div
            className={`${style.bgd} ${isOpened ? style.opened : ''}`}
            // Uncomment the following lines to close the window when clicking outside of it
            /*onMouseUp={(event) => {
                if (
                    mouseDownTarget.current &&
                    ref.current &&
                    !ref.current.contains(mouseDownTarget.current as Node) &&
                    !ref.current.contains(event.target as Node)
                ) {
                    onClose(event);
                }
            }}
            onMouseDown={(event) => {
                mouseDownTarget.current = event.target;
            }}*/
        >
            <div className={style.window} ref={ref}>
                <div className={style.content}>{children}</div>
            </div>
            {customImage?.Image && <customImage.Image className={[style.custom, customImage.className].join(' ')} />}
        </div>
    );
};

export default Window;