/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Exercise } from '../../models/Exercise';
import { ExerciseItem } from '../../models/ExerciseItem';
import { randomPicture } from '../../utilities/utils';
import { UserContext } from '../../providers/UserProvider';
import { Roles } from '../../models/Roles';
import { UserDataProp } from '../../models/userDataProp';
import { ExerciseType } from '../../models/ExerciseType';
import { MemorinCard } from './MemorizCard';
import { GameRef } from '../../providers/GameProvider';

import styles from './styles.module.css';
import { useGame } from '../../contexts/GameContext';
import { getGame } from '../../stores/Game';
import { Media } from '../../models/Media';

export interface CardObj {
    id: string;
    image: string | File;
    flipped: boolean;
    found: boolean;
    activeClue: boolean;
    correct: boolean;
    incorrect: boolean;
    idNumber: number;
    alreadyTurn: boolean;
}

export const Memory = React.forwardRef<GameRef>((_, ref) => {
    const { endAnimation, writeMessage, startTimer, stopTimer, endGame, resetTimer, displayInstruction, displayFunfact, closeFunfact } = useGame();
    const { id } = useParams<{ id: string }>();
    const { lvlId } = useParams<{ lvlId: string }>();
    const level = parseInt(lvlId);
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [exercise, setExercise] = useState<Exercise>();
    const [exerciseItemList, setExerciseItemList] = useState<Media[]>([]);
    const [cardsItem, setCardsItem] = useState<CardObj[]>([]);
    const [selectedCards, setSelectedCards] = useState<CardObj[]>([]);
    const [turns, setTurns] = useState<number>(0);
    const [score, setScore] = useState<number>(0);
    const [activClue, setActivClue] = useState(false);
    const [errorCount, setErrorCount] = useState<number>(0);
    const [clueCount, setClueCount] = useState<number>(0);
    const [time, setTime] = useState<number>(5);
    const timerRef = useRef<ReturnType<typeof setInterval> | null>(null);

    useImperativeHandle(ref, () => ({
        tips: () => {
            handleClueClick();
        },
        reset: () => {
            setCardsItem(cardsItem.map((card) => ({ ...card, flipped: false, found: false })));
            setSelectedCards([]);
            setTurns(0);
            setScore(0);
            setActivClue(false);
            setErrorCount(0);
            setClueCount(0);
            resetTimer();
        },
    }));

    useEffect(() => {
        const foundCardsCount = cardsItem.filter(card => card.found).length;
        if (foundCardsCount !== 0 && foundCardsCount === cardsItem.length) {
            stopTimer();
            if (timerRef.current !== null) {
                clearInterval(timerRef.current);
            }
            const funFact = exerciseItemList.find(item => item.anecdote) || exerciseItemList[Math.floor(Math.random() * exerciseItemList.length)];
            endAnimation(async () => displayFunfact({
                title: funFact?.title || '',
                text: funFact?.anecdote,
                Picture: funFact?.url as string,
                onClose: () => {
                    closeFunfact();
                    endGame({
                        clueCount,
                        errorCount,
                    });
                },
            }));
        }
    }, [cardsItem]);

    useEffect(() => {
        if (user?.role === Roles.SENIOR) {
            localStorage.setItem('startTime', new Date().toString());
        }
        displayInstruction();
    }, []);

    const handleClick = (cardIndex: number, card: CardObj): void => {
        if (turns === 0) {
            startTimer();
        }
        setTurns(turns + 1);
        if (selectedCards.length < 2) {
            if (cardsItem) {
                if (cardsItem[cardIndex].flipped === true || cardsItem[cardIndex].found === true) {
                    return;
                }

                const newState = cardsItem.map((ownCard, index) => {
                    if (index === cardIndex) {
                        return { ...ownCard, flipped: true, activeClue: false, alreadyTurn: true };
                    }
                    return { ...ownCard, activeClue: false };
                });

                setCardsItem(newState);
            }
            if (card.flipped === true || card.found === true) {
                return;
            }
            setSelectedCards([...selectedCards, card]);
        }
    };

    useEffect(() => {
        if (selectedCards.length === 2) {
            if (cardsItem) {
                let newState: CardObj[] = [];
                let firstState: CardObj[] = [];
                if (selectedCards[0].id === selectedCards[1].id) {
                    setScore(score + 1);
                    firstState = cardsItem.map((oneCard) => {
                        if (oneCard.idNumber === selectedCards[1].idNumber) {
                            return { ...oneCard, correct: true };
                        }
                        return oneCard;
                    });
                    setTimeout(() => {
                        writeMessage({ color: 'success', text: 'Bravo !' });
        
                        setCardsItem(firstState);
                    }, 1500);

                    newState = cardsItem.map((oneCard) => {
                        if (oneCard.id === selectedCards[0].id) {
                            return { ...oneCard, found: true, flipped: false };
                        }
                        return oneCard;
                    });

                    setActivClue(false);
                    setTimeout(() => {
        
                        setCardsItem(newState);
                        setSelectedCards([]);
                    }, 3500);
                } else {
                    if (selectedCards[1].alreadyTurn === true) {
                        if (
                            exercise &&
                            user &&
                            user.id &&
                            exercise.id &&
                            user.role === Roles.SENIOR
                        ) {
                            setErrorCount(errorCount + 1);
                        }
                    }
                    firstState = cardsItem.map((oneCard) => {
                        if (oneCard.id === selectedCards[1].id) {
                            return { ...oneCard, incorrect: true };
                        }
                        return oneCard;
                    });
                    newState = cardsItem.map((oneCard) => {
                        if (
                            (oneCard.id === selectedCards[0].id ||
                                oneCard.id === selectedCards[1].id) &&
                            !activClue
                        ) {
                            return { ...oneCard, flipped: false, incorrect: false };
                        }
                        if (oneCard.id === selectedCards[1].id && activClue) {
                            return { ...oneCard, flipped: false, incorrect: false };
                        }
                        return oneCard;
                    });
                    setTimeout(() => {
        
                        setCardsItem(firstState);
                        writeMessage({ color: 'wrong', text: 'Retentez votre chance ! Retournez une autre carte.' });
                    }, 1000);
                    setTimeout(() => {
        
                        setCardsItem(newState);
                        if (activClue) {
                            setSelectedCards([selectedCards[0]]);
                        } else {
                            setSelectedCards([]);
                        }
                    }, 3000);
                }
            }
        }
    }, [selectedCards]);

    const getItemList = async (globalExercise: Exercise): Promise<void> => {
        let listArray: number[] = [];
        const list: Media[] = [];
        const listIds: string[] = [];
        listArray = randomPicture(level, ExerciseType.MEMORIZ);
        if (globalExercise.mediaList) {
            await Promise.all(
                listArray.map(async (element) => {
                    if (globalExercise.mediaList && globalExercise.mediaList[element]) {
                        const item = globalExercise.mediaList[element];
                        if (item && item.id) {
                            list.push(item);
                            listIds.push(item.id);
                        }
                    }
                })
            );
            setExerciseItemList(list);
        }
    };

    useEffect(() => {
        const getExerciseList = async (): Promise<void> => {
            if (user && user.id) {
                const globalExercise = await getGame(userDataProp.token ?? '', id);
                if (globalExercise) {
                    const arrayToAddImg = [
                        '26e4de56-0f0e-4ace-9e6c-dd3c440026ef',
                        'a22d2136-1cd0-4536-8de8-d187c271b2ad',
                        'a47f8b3e-48a9-4bca-9523-e7d5a59b9770',
                        'fd56249c-3939-4d56-8310-104ae8e9b040',
                        'c22217df-5cba-468b-8df8-3d6c1fa0fade',
                        '8e3452ab-b61d-4672-9b3f-6b498a8500c9',
                        'af9f5eca-5959-41bc-b706-9faf933ba591',
                        'df5b7a89-5708-4e97-825f-54315dcb6028',
                        'b00afe3f-318b-4bcb-bcc8-b44f1b3b741e',
                        '81e908b6-a35a-4989-a021-70e372575579'
                    ];
                    for (let i = 0; i < 18; i++) {
                        if (globalExercise.exerciseImages && !globalExercise.exerciseImages[i]) {
                            globalExercise.exerciseImages.push(arrayToAddImg[i - 8]);
                        }
                    }
                    setExercise(globalExercise);
                    await getItemList(globalExercise);
                }
            }
        };
        getExerciseList();
    }, [user, id]);

    useEffect(() => {
        if (exerciseItemList.length) {
            const temp: CardObj[] = [];
            let tempIdNumber = 0;
            exerciseItemList.map(async (item) => {
                if (item.id && item.url) {
                    tempIdNumber += 1;
                    const temp1: CardObj = {
                        id: item.id,
                        image: item.url,
                        flipped: false,
                        found: false,
                        activeClue: false,
                        correct: false,
                        incorrect: false,
                        idNumber: tempIdNumber,
                        alreadyTurn: false
                    };
                    tempIdNumber += 1;
                    const temp2: CardObj = {
                        id: item.id,
                        image: item.url,
                        flipped: false,
                        found: false,
                        activeClue: false,
                        correct: false,
                        incorrect: false,
                        idNumber: tempIdNumber,
                        alreadyTurn: false
                    };
                    temp.push(temp1);
                    temp.push(temp2);
                }
            });
            setCardsItem(temp.sort(() => Math.random() - 0.5));
        }
    }, [exerciseItemList]);

    const displayClue = (): void => {
        if (cardsItem.length) {
            if (selectedCards.length === 0) {
                let loop2 = true;
                const newState = cardsItem.map((oneCard) => {
                    if (!oneCard.flipped && !oneCard.found && loop2) {
                        loop2 = false;
                        return { ...oneCard, activeClue: true };
                    }
                    return oneCard;
                });

                setCardsItem(newState);
            }
            if (selectedCards.length === 1) {
                const newState = cardsItem.map((oneCard) => {
                    if (oneCard.id === selectedCards[0].id && !oneCard.flipped) {
                        return { ...oneCard, activeClue: true };
                    }
                    return oneCard;
                });
                setCardsItem(newState);
            }
        }
    };

    const tick = (): void => {
        if (time === 0) {
            setTime(10);
            displayClue();
        }
        if (time > 0) {
            setTime(time - 1);
        }
    };

    useEffect(() => {
        timerRef.current = setInterval(() => tick(), 1000);
        return () => {
            if (timerRef.current !== null) {
                clearInterval(timerRef.current);
            }
        };
    }, [time]);

    const handleClueClick = (): void => {
        if (selectedCards.length === 0) {
            setActivClue(true);
            let loop = true;
            cardsItem.map((item, index) => {
                if (!item.flipped && !item.found && loop) {
                    loop = false;
                    handleClick(index, item);
                }
            });
        }
        if (selectedCards.length === 1) {
            const newState = cardsItem.map((oneCard) => {
                if (oneCard.id === selectedCards[0].id && !oneCard.flipped) {
                    return { ...oneCard, activeClue: true };
                }
                return oneCard;
            });
            setCardsItem(newState);
        }
        setClueCount(clueCount + 1);
    };

    return (
        <>
            <div className={styles.container}>
                {cardsItem.map((card, index) => {
                    return (
                        <MemorinCard
                            lvl={level}
                            key={`card_number_${index}`}
                            card={card}
                            handleClick={handleClick}
                            cardIndex={index}
                        />
                    );
                })}
            </div>
        </>
    );
});
