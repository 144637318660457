import { Button } from "../../components/Button/index";
import Heading from "../../components/Heading";
import ProfilePicture from "../../components/ProfilePicture";
import { GameProps } from "../../providers/GameProvider";
import styles from './style.module.css';

interface RewardProps {
    onClose: () => void;
    congratulation: GameProps['congratulation'];
    colors: {
        primary: string;
        secondary: string;
        border: string;
    }
}

export default ({ onClose, congratulation, colors }: RewardProps) => {
    return (
        <div className={styles.root}>
            <ProfilePicture className={styles.picture} picture={congratulation?.image} />
            <Heading className={styles.title}>{congratulation?.firstName} a une suprise pour vous…</Heading>
            <div className={styles.text}>{congratulation?.msg}</div>
            <Button className={styles.cta} label="Continuer" style={{ backgroundColor: colors.secondary }} action={onClose} />
        </div>
    );
};