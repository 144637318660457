/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-useless-path-segments */
import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Button } from '../../../components';
import { fillBlock, IReducer } from '../../../store';
import { BLOCK_COORDS, N, NUMBERS } from '../../../typings';

interface IProps {
    value: NUMBERS;
}

interface IState {
    selectedBlock?: BLOCK_COORDS;
    selectedValue: N;
}

const NumberButton: FC<IProps> = ({ value }) => {
    const state = useSelector<IReducer, IState>(
        ({ selectedBlock, workingGrid }) => ({
            selectedBlock,
            selectedValue:
                workingGrid && selectedBlock ? workingGrid?.[selectedBlock?.[0]]?.[selectedBlock?.[1]] : 0
        }),
        (prev, next) => {
            return (
                prev.selectedBlock === next.selectedBlock && prev.selectedValue === next.selectedValue
            );
        }
    );
    const dispatch = useDispatch<Dispatch<AnyAction>>();

    const fill = useCallback(() => {
        if (state.selectedBlock && state.selectedValue === 0)
            // eslint-disable-next-line curly
            dispatch(fillBlock(value, state.selectedBlock));
    }, [dispatch, state.selectedBlock, state.selectedValue, value]);

    return (
        <Button
            onClick={fill}
            style={{
                fontSize: '5vh',
                fontWeight: 'bold',
                flex: '30%',
                height: 'auto',
                margin: '0 0.5rem 0.5rem 0',
                padding: '0.5rem 0'
            }}>
            {value}
        </Button>
    );
};

// eslint-disable-next-line import/no-default-export
export default NumberButton;
