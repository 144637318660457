import { useContext, useEffect, useRef, useState } from 'react';

import { puzzle } from '../../../models/Games';
import { Puzzle } from '../../../components/Puzzle';
import { GameProps, GameProvider, GameRef } from '../../../providers/GameProvider';
import { UserDataProp } from "../../../models/userDataProp";
import { UserContext } from "../../../providers/UserProvider";
import { getPatientUser } from "../../../services/cloudFirestore";
import { Roles } from "../../../models/Roles";
import { Loader } from '../../../components/Loader';
import { useParams } from 'react-router-dom';
import { getGame } from '../../../stores/Game';
import { getUserById } from '../../../stores/User';

export default () => {
    const puzzleRef = useRef<GameRef>(null);
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [encouragement, setEncouragement] = useState<GameProps['encouragement'] | undefined>(undefined);
    const [congratulation, setCongratulation] = useState<GameProps['congratulation'] | undefined>(undefined);
    const [endloading, setEndLoading] = useState<boolean>(false);
    const [refreshKey, setRefreshKey] = useState<number>(0);
    const { lvlId } = useParams<{ lvlId: string }>();
    const lvl = parseInt(lvlId);

    useEffect(() => {
        if (localStorage.getItem('reload') === 'true') {
            localStorage.setItem('reload', 'false');
            window.location.reload();          
        }
        const getGames = async (): Promise<void> => {
            const gameId = window.location.pathname.split('/')[2]
            if (!gameId) return;
            const game = await getGame(userDataProp.token ?? '', gameId);
            const seniorData = await getPatientUser(user?.id ?? '');
            const author = await getUserById(userDataProp.token ?? '', game?.author ?? '');
            let tempNbRealisation = 0;
            seniorData?.statistics?.map((statistic) => {
                if (statistic.exerciseId === game?.id) {
                    tempNbRealisation = statistic.details.length;
                }
            });
            setCongratulation({
                msg: game?.congratulationsmessage,
                video: game?.reward as string || undefined,
                image: author?.profilePhoto as string ?? '',
                firstName: author?.firstName ?? '',
                lastName: author?.lastName ?? '',
            });
            setEncouragement({
                msg: game?.encouragementmessage ?? '',
                nbRealisation: tempNbRealisation,
                image: author?.profilePhoto as string ?? '',
                firstName: author?.firstName ?? '',
                lastName: author?.lastName ?? '',
            });

            setEndLoading(true);
        };
        
        if (user && (user.role === Roles.SENIOR || user.role === Roles.FAMILY)) getGames();
        else setEndLoading(true);
    }, []);

    const getTips = () => {
        if (!puzzleRef.current) return;
        puzzleRef.current?.tips();
    };

    const resetGame = () => {
        setRefreshKey((prev) => prev + 1);
    }

    const colors = {
        primary: puzzle.color,
        secondary: puzzle.secondaryColor,
        border: puzzle.border,
    };

    if (!endloading) return (<Loader />);

    return (
        <GameProvider
            onTipsClick={getTips}
            colors={colors}
            congratulation={congratulation}
            encouragement={encouragement}
            onReset={resetGame}
            background={puzzle.background}
            instructionMessage={puzzle.instructions?.find((instruction) => instruction.maxLvl >= lvl)?.text}
        >
            {endloading && <Puzzle key={refreshKey} ref={puzzleRef} />}
        </GameProvider>
    );
};
