import { useRef } from 'react';

import { tourbillon } from '../../../models/Games';
import { GameProvider, GameRef } from '../../../providers/GameProvider';
import { Tourbillon } from '../../../components/Tourbillon';
import { useParams } from 'react-router-dom';

export default () => {
    const tourbillonRef = useRef<GameRef>(null);
    const { lvlId } = useParams<{ lvlId: string }>();
    const lvl = parseInt(lvlId);

    const getTips = () => {
        if (!tourbillonRef.current) return;
        tourbillonRef.current?.tips();
    };

    const resetGame = () => {
        if (!tourbillonRef.current) return;
        tourbillonRef.current?.reset();
    };

    const colors = {
        primary: tourbillon.color,
        secondary: tourbillon.secondaryColor,
        border: tourbillon.border,
    };

    return (
        <GameProvider
            onTipsClick={getTips}
            colors={colors}
            onReset={resetGame}
            instructionMessage={tourbillon.instructions?.find((instruction) => instruction.maxLvl >= lvl)?.text}
            background={tourbillon.background}
        >
            <Tourbillon ref={tourbillonRef} />
        </GameProvider>
    );
};
