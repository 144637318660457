import { FC, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { GameContext } from '../contexts/GameContext';
import GameBackground from '../components/GameBackground';
import GameHeader from '../components/GameHeader';
import Instructions from '../components/Instructions';
import Tips, { Message } from '../components/Tips';
import Funfact, { FunfactProps } from '../pages/games/Funfact';
import Encouragement from '../pages/games/Encouragement';
import Reward from '../pages/games/Reward';
import { NextGameWindow } from '../windows/NextGame';
import { useWindow } from '../contexts/WindowContext';
import { UserContext } from './UserProvider';
import { UserDataProp } from '../models/userDataProp';
import { useParams } from 'react-router';
import { tourbillon, pingpong, memory, sudok, puzzle, reordon, melimots, quizz } from '../models/Games';
import { Timestamp } from 'firebase/firestore';
import { insertStats } from '../stores/Stats';
import checkAnimation from '../assets/checkAnimation.json';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import RewardPopup from '../windows/Reward';
import Bravo from '../assets/bravo.svg?react';

import style from './style.module.css';

export interface GameRef {
    tips: () => void;
    reset: () => void;
};

export interface GameProps {
    children: ReactNode;
    onTipsClick: () => void;
    colors: {
        primary: string;
        secondary: string;
        border: string;
    },
    onReset: () => void;
    background?: string;
    tipsEnabled?: boolean;
    instructionEnabled?: boolean;
    instructionMessage?: string;
    congratulation?: {
        title?: string;
        msg?: string;
        video?: string;
        image?: string;
        firstName?: string;
        lastName?: string;
    };
    encouragement?: {
        msg: string;
        nbRealisation: number;
        image: string;
        firstName: string;
        lastName: string;
    };
}

export const GameProvider: FC<GameProps> = ({
    children: Game,
    onTipsClick,
    colors,
    onReset,
    background,
    tipsEnabled,
    instructionEnabled,
    instructionMessage,
    encouragement,
    congratulation,
}) => {
    let timer: NodeJS.Timeout | null = null;
    const { openWindow, closeWindow } = useWindow();
    const [time, setTime] = useState(0);
    const userDataProp: UserDataProp = useContext(UserContext);
    const user = userDataProp.user;
    const { id: gameId, lvlId } = useParams<{ id: string, lvlId: string }>();
    const gameTitle = window.location.pathname.split('/')[1];
    const game = {
        tourbillon,
        pingpong,
        memory,
        sudok,
        puzzle,
        reordon,
        melimots,
        quizz
    }[gameTitle] || null;
    const [showInstruction, setShowInstruction] = useState(instructionEnabled);
    const [showTips, setShowTips] = useState(tipsEnabled);
    const [points, setPoints] = useState<number | undefined>();
    const [message, setMessage] = useState<Message | undefined>();
    const [funfact, setFunfact] = useState<FunfactProps | undefined>();
    const [displayEncouragement, setDisplayEncouragement] = useState(
        encouragement?.firstName
        && encouragement.lastName
        && encouragement.nbRealisation >= 0
        && encouragement.nbRealisation % 5 === 0
        && encouragement.msg
    );
    const [displayCongratulation, setDisplayCongratulation] = useState(false);
    const [blurred, setBlurred] = useState(false);
    const lottieRef = useRef<LottieRefCurrentProps | null>(null);
    const [displayBravo, setDisplayBravo] = useState(false);

    const onStartUse = () => {
        setBlurred((prevBlurred) => {
            if (prevBlurred) {
                if (message && message.color === 'info' && message.text === instructionMessage) {
                    setMessage(undefined);
                }
                return false;
            }
            return prevBlurred;
        });
    };

    useEffect(() => {
        const isInstruction = message && message.color === 'info' && message.text === instructionMessage;
        if (isInstruction) {
            setBlurred(true);
        }
        const timer = setTimeout(() => {
            setMessage(undefined);
            if (isInstruction) {
                setBlurred(false);
            }
        }, isInstruction ? 15000 : 5000);

        return () => clearTimeout(timer);
    }, [message]);

    const actions = useMemo(() => ({
        startTimer: () => {
            if (timer === null) {
                setTime(0);
                showUi();
                timer = setInterval(() => {
                    setTime((prevTime) => prevTime + 1);
                }, 1000);
            }
        },
        stopTimer: () => {
            if (timer !== null) {
                clearInterval(timer);
                timer = null;
            }
        },
        resetTimer: () => {
            setTime(0);
        },
        resetPoints: () => {
            setPoints(0);
        },
        addPoints: (value: number) => {
            setPoints((prevPoints) => prevPoints ? prevPoints + value : value);
        },
    }), []);


    const handleTipsClick = () => onTipsClick();

    const handleInstructionsClick = () => {
        setMessage({
            color: 'info',
            text: instructionMessage || 'Posez votre doigt sur la bonne réponse',
        })
        setBlurred(true);
    };

    const handleCloseEncouragement = () => setDisplayEncouragement(false);

    const displayFunfact = (funfact: FunfactProps) => {
        setFunfact(funfact);
    };

    const closeFunfact = () => {
        setFunfact(undefined);
    };

    const showUi = () => {
        setShowInstruction(true);
        setShowTips(true);
    };

    const hideUi = () => {
        setShowInstruction(false);
        setShowTips(false);
    }

    const saveData = (props: any) => {
        setTime((currentTime) => {
            if (user && game) {
                const stats = {
                    idGame: gameId,
                    clueCount: props.clueCount,
                    datetime: Timestamp.now(),
                    difficultyLevel: parseInt(lvlId),
                    errorCount: props.errorCount,
                    memoryCardFind: props.memoryCardFind,
                    memoryCardReturn: props.memoryCardReturn,
                    pongPlayerScore: props.pongPlayerScore,
                    pongComputerScore: props.pongComputerScore,
                    timeTakenToCompleteGame: currentTime,
                }
                insertStats(userDataProp.token ?? '', stats);
            }
            return currentTime;
        });
    };

    const endAnimation = (callback: () => {}) => {
        if (lottieRef.current) {
            const time = lottieRef.current.getDuration() || 0;
            setMessage({ color: 'success', text: 'Bravo !' });
            setTimeout(() => {
                hideUi();
                setDisplayBravo(true);
                lottieRef?.current?.play()
            }, 1500);
            setTimeout(() => {
                lottieRef?.current?.stop();
                showUi();
                setDisplayBravo(false);
                return callback();
            }, (time + 2.5) * 1000);
        }
    };

    const rewardPopup = (callback: () => {}) => {
        if (openWindow && closeWindow)
            openWindow({
                component: () => <RewardPopup
                    onClose={() => {
                        closeWindow();
                        callback();
                    }}
                    congratulation={congratulation}
                    colors={colors}
                />,
            });
    };

    const nextGamePopup = () => {
        if (openWindow && closeWindow)
            openWindow({
                component: () => <NextGameWindow
                    colors={colors}
                    onClose={() => {
                        setDisplayCongratulation(false);
                        closeWindow();
                    }}
                    onReset={() => {
                        setDisplayCongratulation(false);
                        hideUi();
                        onReset();
                        closeWindow();
                    }}
                />,
            });
    }

    const endGame = (props: any) => {
        saveData(props);
        if (congratulation?.msg) {
            rewardPopup(async () => setDisplayCongratulation(true));
        }
        else if (congratulation?.video) {
            setDisplayCongratulation(true);
        } else {
            nextGamePopup();
        }
    };

    return (
        <GameContext.Provider value={{
            startTimer: actions.startTimer,
            stopTimer: actions.stopTimer,
            resetTimer: actions.resetTimer,
            resetPoints: actions.resetPoints,
            addPoints: actions.addPoints,
            writeMessage: setMessage,
            displayFunfact,
            closeFunfact,
            endGame,
            endAnimation,
            displayInstruction: handleInstructionsClick,
            time,
            points,
            showUi,
            hideUi,
        }}>
            <GameBackground onBlur={!!blurred} background={funfact ? 'white' : background} border={colors.border}>
                {displayBravo && <div className={style.bravoFilter} />}
                <Bravo className={`${style.bravo} ${displayBravo ? style.bravoDisplayed : ''}`} />
                <Lottie autoplay={false} loop={false} lottieRef={lottieRef} animationData={checkAnimation} className={style.lottie} />
                <GameHeader quitAction onTouch={() => onStartUse()} color={colors.secondary} time={funfact || displayEncouragement || displayCongratulation ? undefined : time} rtnAction={() => history.back()} />
                {displayEncouragement && <Encouragement onClose={handleCloseEncouragement} encouragement={encouragement} />}
                {displayCongratulation && <Reward onNext={() => { nextGamePopup(); }} congratulation={congratulation} />}
                {!displayEncouragement && !displayCongratulation &&
                    <>
                        {funfact && <Funfact {...funfact} color={colors.secondary} />}
                        <div id='game-root' style={{ display: funfact ? 'none' : 'block', 'width': '100%', 'height': 'calc(100dvh - 100px)' }} onTouchStart={() => onStartUse()} onMouseDown={() => onStartUse()}>{Game}</div>
                        {message === undefined && !funfact && showInstruction && <Instructions onClick={handleInstructionsClick} />}
                        {!funfact && showTips && <Tips message={message} onClick={handleTipsClick} />}
                    </>}
            </GameBackground>
        </GameContext.Provider>
    );
};

// TODO : Récupérer le temps de jeu, le score, lvl, date, nb d'erreur, nb d'indices demandés