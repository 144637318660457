import React, { useImperativeHandle } from "react";
import { GameRef } from "../../providers/GameProvider";
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { Capacitor } from '@capacitor/core';
import { UserDataProp } from "../../models/userDataProp";
import { UserContext } from "../../providers/UserProvider";
import { Patient } from "../../models/Patient";
import { Roles } from "../../models/Roles";
import PongComponent from "./sketch";
import { useGame } from "../../contexts/GameContext";

export const PingPong = React.forwardRef<GameRef>((_, ref) => {
    const { endAnimation, endGame } = useGame();
    const { lvlId } = useParams<{ lvlId: string }>();
    const lvl = parseInt(lvlId);
    const platform = Capacitor.getPlatform();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [patient, setPatient] = useState<Patient>();
    const [cpuMode, setCPUMode] = useState<boolean | undefined>(true);
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
    const [pongPlayerScore, setPongPlayerScore] = useState<number>(0);
    const [pongCpuScore, setPongCpuScore] = useState<number>(0);
    const [refreshKey, setRefreshKey] = useState(0);
    const ballSpeed = [4, 5, 6, 8, 10, 13, 16, 20, 22, 25];
    const cpuSpeed = [
        { min: 1, max: 5 },
        { min: 4, max: 7 },
        { min: 6, max: 9 },
        { min: 8, max: 14 },
        { min: 9, max: 15 },
        { min: 7, max: 16 }, // original
        { min: 10, max: 18 },
        { min: 13, max: 22 },
        { min: 16, max: 25 },
        { min: 18, max: 28 }
    ];
    const scoreNeedToWin = [5, 7, 10, 15, 20, 20, 25, 25, 30, 30];

    useImperativeHandle(ref, () => ({
        tips: () => {
        },
        reset: () => {
            setRefreshKey(oldKey => oldKey + 1);
        },
    }));

    useEffect(() => {
        if (user?.role === Roles.SENIOR) {
            localStorage.setItem('startTime', new Date().toString());
        }
    }, [user]);

    const finishGame = (playerScore: number, cpuScore: number): void => {
        endAnimation(async () => endGame({
            pongComputerScore: cpuScore,
            pongPlayerScore: playerScore,
        }));
        setPongPlayerScore(playerScore);
        setPongCpuScore(cpuScore);
    };

    return (
        <PongComponent
            key={refreshKey}
            cpuMode={cpuMode !== false}
            scoreNeedToWin={scoreNeedToWin[lvl - 1]}
            ballSpeed={ballSpeed[lvl - 1]}
            cpuSpeed={cpuSpeed[lvl - 1]}
            finishGame={finishGame}
            userImage={typeof user?.profilePhoto === 'string' ? user?.profilePhoto : ''}
        />
    );
});
